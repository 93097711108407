import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { Box, Collapse, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  OptionSetParam,
  FieldParamValue,
  OptionLegParam,
  OptionStrategyParam,
  CopiedStrategyParam,
  OptionDayWiseParam,
  DAY_WISE_STOPLOSS,
  DAY_WISE_TARGETPROFIT,
  DAY_WISE_TARGETSTOPLOSS,
  DayWiseKey,
} from './types';
import {
  SbStrategy,
  Universe,
} from 'app/components/Bots/StrategyBuilder/types';
import {
  appendExtraSetFields,
  CheckValidate,
  createEmptyOptionDayWiseParam,
  createEmptyOptionSet,
  generateOptionDayWiseParentKey,
} from './Utils/utils';
import OptionSet from './OptionSet';
import { cloneDeep } from 'lodash';
import {
  OptionSetFieldsGroupWise,
  OptionStrategyStopLossFieldsGroupWise,
  OptionStrategyTargetProfitFieldsGroupWise,
  OptionStrategyTrailingStopLossFieldsGroupWise,
} from './FieldsData';
import { useRef, useEffect } from 'react';
import { generateParentKey } from '../../Builder/OptionsStrategy/Utils/utils';
import MuiBuilderButton from 'app/design/uiComponents/MuiBuilderButton';
import OptionDayWiseSSL from './OptionDayWiseSSL';
import { FeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import {
  BANKEX,
  CONVERT_MARKET_TO_USA,
  FIN_NIFTY_SYMBOL,
  SENSEX,
  SPX,
  XSP,
} from 'constants/common';
import { FieldDataConditions } from '../Strategy/fields/types';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useTranslation } from 'react-i18next';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { toTitleCase } from 'utils/GenericFunctions';
import { useTheme } from '@mui/material/styles';

interface Props {
  optionBuilderData: SbStrategy;
  onChangeBuilderData: (
    data: SbStrategy,
    validationData: boolean,
    validate: boolean,
  ) => void;
  isReadOnly: boolean;
  setValidationMessage?: (val: string) => void;
  isShowOptionSetValidation?: boolean;
}

const OptionsBuilder = (props: Props) => {
  const {
    optionBuilderData,
    setValidationMessage,
    onChangeBuilderData,
    isReadOnly,
    isShowOptionSetValidation,
  } = cloneDeep(props);

  const { sb_opt_strategy } = optionBuilderData;
  const [formikValue, setFormikValue] = useState<FieldDataConditions>({
    isConditionValid: true,
    entry_conditions: [],
    exit_conditions: [],
    resolution: '00:10:00',
  } as FieldDataConditions);

  const initialValidationRefValue = useMemo(() => {
    return (sb_opt_strategy.strategy_sets as OptionSetParam[]).reduce(
      (acc, crr, i) => {
        acc[`option${i}`] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  }, [sb_opt_strategy.strategy_sets]);

  const validationDataRef = useRef(initialValidationRefValue);
  const theme = useTheme();
  const [optionSetWithParentKey, setOptionSetWithParentKey] = useState<
    OptionSetParam[]
  >(
    sb_opt_strategy.strategy_sets && sb_opt_strategy.strategy_sets.length
      ? generateParentKey(
          appendExtraSetFields(
            sb_opt_strategy.strategy_sets as OptionSetParam[],
          ),
        )
      : [],
  );
  const { showAlert } = useContext(ToastContext);
  const { t } = useTranslation();
  const [optionDayWiseWithParentKey, setOptionDayWiseWithParentKey] = useState({
    day_wise_sl:
      sb_opt_strategy &&
      sb_opt_strategy.day_wise_sl &&
      sb_opt_strategy.day_wise_sl.length
        ? generateOptionDayWiseParentKey(
            sb_opt_strategy.day_wise_sl as OptionDayWiseParam[],
            DAY_WISE_STOPLOSS,
          )
        : ([] as OptionDayWiseParam[]),
    day_wise_tg:
      sb_opt_strategy &&
      sb_opt_strategy.day_wise_tg &&
      sb_opt_strategy.day_wise_tg.length
        ? generateOptionDayWiseParentKey(
            sb_opt_strategy.day_wise_tg as OptionDayWiseParam[],
            DAY_WISE_TARGETPROFIT,
          )
        : ([] as OptionDayWiseParam[]),
    day_wise_tsl:
      sb_opt_strategy &&
      sb_opt_strategy.day_wise_tsl &&
      sb_opt_strategy.day_wise_tsl.length
        ? generateOptionDayWiseParentKey(
            sb_opt_strategy.day_wise_tsl as OptionDayWiseParam[],
            DAY_WISE_TARGETSTOPLOSS,
          )
        : ([] as OptionDayWiseParam[]),
  });

  const [copiedOptionStrategy, setCopiedOptionStrategy] =
    useState<CopiedStrategyParam>({ set: {}, leg: {}, hedge: {} });

  if (optionSetWithParentKey.length === 0) {
    const updatedData = generateParentKey(
      sb_opt_strategy.strategy_sets as OptionSetParam[],
    );
    sb_opt_strategy.strategy_sets = updatedData;
  } else {
    sb_opt_strategy.strategy_sets = optionSetWithParentKey; //for add removed parent_ key in data which has removed when send data to its parent component
  }
  if (optionDayWiseWithParentKey.day_wise_sl.length === 0) {
    sb_opt_strategy.day_wise_sl = generateOptionDayWiseParentKey(
      sb_opt_strategy.day_wise_sl
        ? (sb_opt_strategy.day_wise_sl as OptionDayWiseParam[])
        : [],
      DAY_WISE_STOPLOSS,
    );
  } else {
    sb_opt_strategy.day_wise_sl = optionDayWiseWithParentKey.day_wise_sl; //for add removed parent_ key in data which has removed when send data to its parent component
  }
  if (optionDayWiseWithParentKey.day_wise_tg.length === 0) {
    sb_opt_strategy.day_wise_tg = generateOptionDayWiseParentKey(
      sb_opt_strategy.day_wise_tg
        ? (sb_opt_strategy.day_wise_tg as OptionDayWiseParam[])
        : [],
      DAY_WISE_TARGETPROFIT,
    );
  } else {
    sb_opt_strategy.day_wise_tg = optionDayWiseWithParentKey.day_wise_tg; //for add removed parent_ key in data which has removed when send data to its parent component
  }
  if (optionDayWiseWithParentKey.day_wise_tsl.length === 0) {
    sb_opt_strategy.day_wise_tsl = generateOptionDayWiseParentKey(
      sb_opt_strategy.day_wise_tsl
        ? (sb_opt_strategy.day_wise_tsl as OptionDayWiseParam[])
        : [],
      DAY_WISE_TARGETSTOPLOSS,
    );
  } else {
    sb_opt_strategy.day_wise_tsl = optionDayWiseWithParentKey.day_wise_tsl; //for add removed parent_ key in data which has removed when send data to its parent component
  }

  const strategy_sets = sb_opt_strategy.strategy_sets as OptionSetParam[];
  const day_wise_sl = sb_opt_strategy.day_wise_sl as OptionDayWiseParam[];
  const day_wise_tg = sb_opt_strategy.day_wise_tg as OptionDayWiseParam[];
  const day_wise_tsl = sb_opt_strategy.day_wise_tsl as OptionDayWiseParam[];
  const formikCallBack = (value: FieldDataConditions) => {
    setFormikValue(value);
  };
  useEffect(() => {
    // Commented By Chetan bardoliya
    // if (strategy_sets.length === 0) {
    //   strategy_sets.push(
    //     createEmptyOptionSet(optionBuilderData.type, OptionSetFieldsGroupWise, strategy_sets.length),
    //   );
    // }
    // handleChangeBuilderData(cloneDeep(optionBuilderData));
    if (strategy_sets.length === 0) {
      const emptySet = createEmptyOptionSet(
        optionBuilderData.type,
        OptionSetFieldsGroupWise,
        strategy_sets.length,
      );
      optionSetWithParentKey.push(emptySet);
      strategy_sets.push(emptySet);
      setOptionSetWithParentKey(cloneDeep(optionSetWithParentKey));
    } else {
      if (strategy_sets && strategy_sets.length) {
        strategy_sets.map(x => {
          x.set_security_type = optionBuilderData.type;
          return x;
        });
      }
    }
    handleChangeBuilderData(cloneDeep(optionBuilderData)); // eslint-disable-next-line
  }, []);

  const handleCopyStrategy = useCallback(
    (strategyName: string, strategyData: OptionSetParam | OptionLegParam) => {
      copiedOptionStrategy[strategyName] = strategyData;
      setCopiedOptionStrategy(cloneDeep(copiedOptionStrategy));
    },
    [copiedOptionStrategy],
  );

  const handlePasteStrategy = useCallback(
    (
      strategyName: string,
      optionSetIndex = -1,
      optionLegIndex = -1,
      optionHedgeIndex = -1,
    ) => {
      if (copiedOptionStrategy[strategyName]) {
        if (
          optionHedgeIndex >= 0 &&
          optionSetIndex >= 0 &&
          optionLegIndex >= 0 &&
          strategyName === 'hedge' &&
          optionSetWithParentKey[optionSetIndex]?.legs[optionLegIndex]
            ?.hedge_legs[optionHedgeIndex]
        ) {
          optionSetWithParentKey[optionSetIndex].legs[
            optionLegIndex
          ].hedge_legs[optionHedgeIndex] = copiedOptionStrategy[strategyName];
        } else if (
          optionSetIndex >= 0 &&
          optionLegIndex >= 0 &&
          strategyName === 'leg' &&
          optionSetWithParentKey[optionSetIndex]?.legs[optionLegIndex]
        ) {
          optionSetWithParentKey[optionSetIndex].legs[optionLegIndex] =
            copiedOptionStrategy[strategyName];
        } else if (
          optionSetIndex >= 0 &&
          strategyName === 'set' &&
          optionSetWithParentKey[optionSetIndex]
        ) {
          optionSetWithParentKey[optionSetIndex] =
            copiedOptionStrategy[strategyName];
        }
      }
      const validationData = validationDataRef.current;
      if (validationData.option0 === true) {
        Object.keys(validationData).forEach(key => {
          validationData[key] = true;
        });
      }

      setOptionSetWithParentKey(cloneDeep(optionSetWithParentKey));
    },
    [copiedOptionStrategy, optionSetWithParentKey],
  );

  const getManipulatedData = useCallback(
    (
      optionSetData:
        | OptionStrategyParam
        | OptionSetParam
        | OptionLegParam
        | OptionDayWiseParam,
    ) => {
      let returnData: OptionStrategyParam = {};

      for (const [key, value] of Object.entries(optionSetData)) {
        if (Object.prototype.hasOwnProperty.call(value, 'toggleStatus')) {
          delete value['toggleStatus'];
        }
        if (key === 'parent_combine_expiry_type_value') {
          optionSetData.parent_expiry_type['expiry_type'] = (
            value['combine_expiry_type_value'] as string
          ).split('_')[0];
          optionSetData.parent_expiry_value['expiry_value'] = (
            value['combine_expiry_type_value'] as string
          ).split('_')[1];
          delete optionSetData[key];
          continue;
        }
        if (key === 'parent_mtm_lock_target_profit_unit') {
          if (
            optionSetData.parent_mtm_lock_target_profit_unit[
              'mtm_lock_target_profit_unit'
            ] === 'none'
          ) {
            optionSetData.parent_mtm_trail_lock_profit_unit[
              'mtm_trail_lock_profit_unit'
            ] = 'none';
            optionSetData.parent_mtm_trail_lock_profit_unit[
              'mtm_target_profit_trail_every'
            ] = '0';
            optionSetData.parent_mtm_trail_lock_profit_unit[
              'mtm_trail_lock_profit_by'
            ] = '0';
            optionSetData.parent_mtm_trail_lock_profit_unit[
              'is_trail_mtm_lock_profit'
            ] = false;
          }
        }
        if (key === 'parent_position_type') {
          if (
            optionSetData.parent_position_type['position_type'] === 'continuous'
          ) {
            returnData['class_name'] =
              'SbExecutor.Executor.SBContinuousIndicatorSet';
          }
        }
        if (key === 'parent_symbol') {
          const usSymbols = new Set(CONVERT_MARKET_TO_USA);
          const bseSymbols = new Set([SENSEX, BANKEX]);
          const baseTypeSymbols = new Set([SPX, XSP]);

          // value['market'] = usSymbols.has(value['symbol'] as string)
          //   ? 'usa'
          //   : bseSymbols.has(value['symbol'] as string)
          //   ? 'bse'
          //   : 'nse';
          if (bseSymbols.has(optionSetData.parent_symbol['symbol'] as string)) {
            optionSetData.parent_symbol['market'] = 'bse';
          } else if (
            usSymbols.has(optionSetData.parent_symbol['symbol'] as string)
          ) {
            optionSetData.parent_symbol['market'] = 'usa';
          } else {
            optionSetData.parent_symbol['market'] = 'nse';
          }

          if (baseTypeSymbols.has(value['symbol'] as string)) {
            (optionSetData['legs'] as OptionLegParam[]).forEach(
              item => (item['base_type'] = 'IndexOption'),
            );
          } else {
            (optionSetData['legs'] as OptionLegParam[]).forEach(item => {
              delete item['base_type'];
            });
          }
        }

        if (key === 'parent_mtm_trail_lock_profit_unit') {
          value['is_trail_mtm_lock_profit'] =
            value['mtm_trail_lock_profit_unit'] === 'absolute';
        }

        if (key === 'parent_combine_contract_selection_price_range') {
          if (
            optionSetData.parent_contract_selection_type[
              'contract_selection_type'
            ] === 'price_range'
          )
            returnData[
              'contract_selection_value'
            ] = `${value['contract_selection_price_range_start']}-${value['contract_selection_price_range_end']}`;
          delete optionSetData[key];
          continue;
        }
        if (key === 'parent_combine_contract_selection_delta_range') {
          if (
            optionSetData.parent_contract_selection_type[
              'contract_selection_type'
            ] === 'delta_range'
          )
            returnData[
              'contract_selection_value'
            ] = `${value['contract_selection_delta_range_start']}-${value['contract_selection_delta_range_end']}`;
          delete optionSetData[key];
          continue;
        }
        if (
          key.includes('parent_') &&
          !!value &&
          value.constructor === Object
        ) {
          returnData = {
            ...returnData,
            ...(value as Record<string, FieldParamValue>),
          } as OptionStrategyParam;
        } else if (Array.isArray(value)) {
          returnData = {
            ...returnData,
            [key]: value.map(item => {
              if (
                optionSetData['parent_position_type'] &&
                optionSetData['parent_position_type']['position_type'] !==
                  'intraday'
              ) {
                item['order_product_type'] = 'cnc';
              } else if (
                optionSetData['parent_position_type'] &&
                optionSetData['parent_position_type']['position_type'] ===
                  'intraday'
              ) {
                item['order_product_type'] =
                  item['productTypeModified'] === 'cnc' ? 'cnc' : 'mis';
              }

              if (
                item &&
                item.parent_stop_loss &&
                item.parent_stop_loss['sl_unit'] === 'none'
              ) {
                item.parent_trail_stop_loss['trailing_sl_type'] = 'none';
                item.parent_trail_stop_loss['trail_sl'] = false;
              }
              if (
                key !== 'day_wise_sl' &&
                key !== 'day_wise_tg' &&
                key !== 'day_wise_tsl'
              ) {
                if (
                  item['legEnabled'] === 'futureleg' ||
                  item['legEnabled'] === 'equityleg'
                ) {
                  item.class_name =
                    item['legEnabled'] === 'futureleg'
                      ? 'SbExecutor.Executor.SBFutureLeg'
                      : 'SbExecutor.Executor.SBEquityLeg';
                  item['type'] =
                    item['legEnabled'] === 'futureleg' ? 'future' : 'equity';
                  delete item['indicator_extras'];
                  delete item['entry_execution_settings'];
                  delete item['exit_execution_settings'];
                  delete item['hedge_legs'];
                  delete item['isHedge'];
                  delete item['contract_selection_strike_stap_size'];
                  delete item['contract_selection_type'];
                  delete item['contract_selection_value'];
                  delete item['right'];
                } else if (item['type'] === 'leg') {
                  item.class_name = 'SbExecutor.Executor.SBOptionLeg';
                  item['type'] = 'leg';
                }
              } else if (key === 'day_wise_sl') {
                if (item['parent_strategy_stop_loss']['unit'] === 'none') {
                  item['parent_strategy_stop_loss']['value'] = '0';
                }
              } else if (key === 'day_wise_tg') {
                if (
                  item['parent_strategy_target_profityy']['unit'] === 'none'
                ) {
                  item.parent_strategy_target_profityy['value'] = '0';
                }
              } else if (key === 'day_wise_tsl') {
                if (
                  item['parent_strategy_trailing_stop_loss'][
                    'activation_unit'
                  ] === 'none'
                ) {
                  item['parent_strategy_trailing_stop_loss'][
                    'activation_value'
                  ] = 0;
                }
                if (
                  item['parent_trail_stop_loss']['movement_type'] === 'none'
                ) {
                  item['parent_trail_stop_loss']['movement_value'] = 0;
                  item['parent_trail_stop_loss']['trail_sl'] = false;
                  item['parent_trail_stop_loss']['trail_value'] = 0;
                }
              }
              return getManipulatedData(item);
            }),
          } as OptionStrategyParam;
        } else {
          returnData = {
            ...returnData,
            [key]: value as FieldParamValue,
          } as OptionStrategyParam;
        }
      }
      return returnData;
    },
    [],
  );

  const handleChangeBuilderData = useCallback(
    (builderData: SbStrategy) => {
      const { sb_opt_strategy } = builderData;
      const finalOptionSet = getManipulatedData(sb_opt_strategy); //for remove "parent_" key in data before send data to its parent component

      builderData.sb_opt_strategy = finalOptionSet; //"parent_" key removed and sending to parent

      // builderData.day_wise_sl = builderData.day_wise_sl
      //   ? builderData.day_wise_sl.map(
      //       x => getManipulatedData(x) as OptionDayWiseParam,
      //     )
      //   : [];
      // builderData.day_wise_tg = builderData.day_wise_tg
      //   ? builderData.day_wise_tg.map(
      //       x => getManipulatedData(x) as OptionDayWiseParam,
      //     )
      //   : [];
      // builderData.day_wise_tsl = builderData.day_wise_tsl
      //   ? builderData.day_wise_tsl.map(
      //       x => getManipulatedData(x) as OptionDayWiseParam,
      //     )
      //   : [];

      const universe: Universe[] = [];
      let holdingTypeBit = 0;
      const mis = 1;
      const cnc = 2;
      strategy_sets.forEach(set => {
        universe.push({
          name: set['name'] as string,
          segment: 'NSE',
          symbol: set['parent_symbol']['symbol'] as string,
        });
        holdingTypeBit =
          holdingTypeBit |
          (set['parent_holding_type']['holding_type'] === 'mis' ? mis : cnc);

        const isFinNify = set.parent_symbol['symbol'] === FIN_NIFTY_SYMBOL;

        set.legs = (set.legs as OptionLegParam[]).map(x => {
          const isMonthly = (
            x.parent_combine_expiry_type_value as Record<
              string,
              FieldParamValue
            >
          )['combine_expiry_type_value']
            .toString()
            .startsWith('monthly');

          x.contract_selection_strike_stap_size =
            isFinNify && isMonthly ? '100' : '0';
          return x;
        });
      });
      let holdingType = '';
      if (holdingTypeBit === cnc) {
        holdingType = 'cnc';
      } else if (holdingTypeBit === mis) {
        holdingType = 'mis';
      }
      const start_date = sb_opt_strategy.start_date as string;
      const end_date = sb_opt_strategy.end_date as string;
      const initial_capital = sb_opt_strategy.initial_capital
        ? (sb_opt_strategy.initial_capital as string)
        : ('100000' as string);
      const brokerage_fees = sb_opt_strategy.brokerage_fees
        ? (sb_opt_strategy.brokerage_fees as string)
        : ('zerodha' as string);
      const strategySets = builderData.sb_opt_strategy[
        'strategy_sets'
      ] as OptionSetParam[];
      const data: SbStrategy = {
        ...builderData,
        universe,
        holding_type: holdingType,
        resolution: '00:01:00',
        start_date,
        end_date,
        initial_capital,
        brokerage_fees,
        sb_opt_strategy: {
          ...builderData.sb_opt_strategy,
          strategy_sets: strategySets.map(x => {
            if (builderData.is_custom_strategy) {
              return { ...x };
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const entryConditionLength =
                x.indicator_extras['entry_conditions'].length;
              delete x.indicator_extras['indicator_extras'];
              return {
                ...x,
                class_name:
                  x.position_type !== 'continuous'
                    ? entryConditionLength > 0
                      ? 'SbExecutor.Executor.SBIndicatorBasedSet'
                      : 'SbExecutor.Executor.SBTimeBaseSet'
                    : 'SbExecutor.Executor.SBContinuousIndicatorSet',
              };
            }
          }),
        },
        override_leverage: 5000,
      };

      let validationStatus = false,
        validationDayWiseStatus = false;
      if (
        Object.keys(validationDataRef.current).filter(e => e.includes('option'))
          .length > 0 &&
        !Object.values(validationDataRef.current).includes(false)
      ) {
        validationStatus = true;
        setValidationMessage!(
          t('user_bot_startegy.entered_invalid_parameters'),
        );
      }
      if (
        Object.keys(validationDataRef.current).filter(e =>
          e.includes('dayWise'),
        ).length === 0 ||
        (Object.keys(validationDataRef.current).filter(e =>
          e.includes('dayWise'),
        ).length > 0 &&
          !Object.values(validationDataRef.current).includes(false))
      ) {
        validationDayWiseStatus = true;
        setValidationMessage!(
          `You've enabled Strategy Stop Loss, Trail Stop Loss, or Target Profit, but haven't set a value for it. Please provide a value. If you choose "Continue Anyway," the above feature will be not be considered`,
        );
      }
      const isValid = CheckValidate(data);

      onChangeBuilderData(
        data,
        validationStatus && validationDayWiseStatus,
        isValid,
      );
    },
    [
      t,
      getManipulatedData,
      onChangeBuilderData,
      strategy_sets,
      setValidationMessage,
    ],
  );
  const sendValidation = useCallback((key: string, isValid: boolean) => {
    validationDataRef.current = {
      ...validationDataRef.current,
      [key]: isValid,
    };
  }, []);
  const [visibleSets, setVisibleSets] = useState<number[]>([0, 1, 2]);

  const onClickAddOptionSet = useCallback(() => {
    // Commented by Chetan Bardoliya
    // strategy_sets.push(
    //   createEmptyOptionSet(optionBuilderData.type, OptionSetFieldsGroupWise, strategy_sets.length),
    // );

    const emptyOptionSet = createEmptyOptionSet(
      optionBuilderData.type,
      OptionSetFieldsGroupWise,
      strategy_sets.length,
    );

    optionSetWithParentKey.push(emptyOptionSet);

    setOptionSetWithParentKey(cloneDeep(optionSetWithParentKey));

    // const fieldDataValidation = OptionSetFieldsGroupWise.reduce((acc, crr) => {
    //   acc = { ...acc, [crr.key]: crr.validation };
    //   return acc;
    // }, {});
    if (sb_opt_strategy.strategy_sets.length === 1) {
      sendValidation('option0', false);
    } else {
      // const lastElem = cloneDeep(
      //   sb_opt_strategy.strategy_sets,
      // ).pop() as OptionSetParam;
      // const returnVal = Ob#ject.keys(lastElem).map(item => {
      //   if (fieldDataValidation[item]) {
      //     if (fieldDataValidation[item].isRequired) {
      //       if (lastElem[item]) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     }
      //   } else {
      //     return true;
      //   }
      // });
      sendValidation(
        `option${
          strategy_sets.length > 0
            ? strategy_sets.length - 1
            : strategy_sets.length
        }`,
        false,
      );
    }
    handleChangeBuilderData(cloneDeep(optionBuilderData));
    if (strategy_sets.length > 0) {
      const lastSetIndex = strategy_sets.length - 1; // Index of the newly added set
      if (!visibleSets.includes(lastSetIndex)) {
        setVisibleSets(prevVisibleSets => {
          const newVisibleSets = [...prevVisibleSets, lastSetIndex];
          // Ensure only the last 3 sets remain expanded
          if (newVisibleSets.length > 3) {
            return newVisibleSets.slice(1);
          }
          return newVisibleSets;
        });
      }
    }
  }, [
    handleChangeBuilderData,
    optionBuilderData,
    optionSetWithParentKey,
    sb_opt_strategy.strategy_sets.length,
    sendValidation,
    strategy_sets.length,
    visibleSets,
  ]);

  // const onChangeBuilder = (key: string, value: FieldParamValue) => {
  //   const data = {
  //     ...optionBuilderData,
  //     sb_opt_strategy: {
  //       ...sb_opt_strategy,
  //       [key]: value,
  //     },
  //   };
  //   handleChangeBuilderData(cloneDeep(data));
  // };

  const onChangeSet = useCallback(
    (
      index: number,
      optionSet: OptionSetParam,
      // isValid = true,
    ) => {
      const isFinNify = optionSet.parent_symbol['symbol'] === FIN_NIFTY_SYMBOL;

      optionSet.legs = (optionSet.legs as OptionLegParam[]).map(x => {
        const isMonthly = (
          x.parent_combine_expiry_type_value as Record<string, FieldParamValue>
        )['combine_expiry_type_value']
          .toString()
          .startsWith('monthly');

        x.contract_selection_strike_stap_size =
          isFinNify && isMonthly ? '100' : '0';
        return x;
      });
      // if (optionSet.isFutureLegEnabled === 'false') {
      if (
        optionSet.parent_combined_momentum_type['combined_momentum_type'] !==
          'none' ||
        optionSet.legs.filter(
          leg => leg.parent_momentum['momentum_type'] !== 'none',
        ).length > 0
      ) {
        optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
      } else {
        optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
      }
      // }
      // if (
      //   Object.prototype.hasOwnProperty.call(
      //     optionSet.parent_combined_momentum_type,
      //     'toggleStatus',
      //   ) &&
      //   !optionSet.parent_combined_momentum_type['toggleStatus']
      // ) {
      //   if (!optionSet.parent_is_momentum_trade['is_momentum_trade']) {
      //     optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
      //   }
      // } else {
      //   if (
      //     optionSet.parent_combined_momentum_type['combined_momentum_type'] ==
      //     'none'
      //   ) {
      //     if (optionSet.parent_is_momentum_trade['is_momentum_trade'] == true) {
      //       optionSet.parent_is_momentum_trade['is_momentum_trade'] = true;
      //     } else {
      //       optionSet.parent_is_momentum_trade['is_momentum_trade'] = false;
      //     }
      //   }
      // }
      // store "parent_" key data before manipulate
      if (optionSetWithParentKey[index]) {
        optionSetWithParentKey[index] = optionSet;
      } else {
        optionSetWithParentKey.push(optionSet);
      }

      setOptionSetWithParentKey(cloneDeep(optionSetWithParentKey));

      strategy_sets[index] = optionSet;

      handleChangeBuilderData(cloneDeep(optionBuilderData));
    },
    [
      handleChangeBuilderData,
      optionBuilderData,
      optionSetWithParentKey,
      strategy_sets,
    ],
  );

  const onDeleteSet = useCallback(
    (index: number) => {
      if (
        validationDataRef.current &&
        Object.keys(validationDataRef.current).length > 0
      ) {
        delete validationDataRef.current[`option${index}`];
        Object.keys(validationDataRef.current).forEach(elem => {
          if (elem.includes('option')) {
            const elemIndex = parseInt(elem.split('option')[1]);
            if (!isNaN(elemIndex) && index < elemIndex) {
              validationDataRef.current[`option${elemIndex - 1}`] =
                validationDataRef.current[elem];
              delete validationDataRef.current[elem];
            }
          }
        });
      }
      strategy_sets.splice(index, 1);
      handleChangeBuilderData(cloneDeep(optionBuilderData));

      optionSetWithParentKey.splice(index, 1);
      setOptionSetWithParentKey(cloneDeep(optionSetWithParentKey));
    },
    [
      handleChangeBuilderData,
      optionBuilderData,
      optionSetWithParentKey,
      strategy_sets,
    ],
  );

  const onClickAddStrategyData = useCallback(
    (key: DayWiseKey) => {
      if (key === DAY_WISE_STOPLOSS) {
        const params = createEmptyOptionDayWiseParam(
          OptionStrategyStopLossFieldsGroupWise,
        );
        // day_wise_sl.push(params);
        optionDayWiseWithParentKey[key].push(params);
      } else if (key === DAY_WISE_TARGETPROFIT) {
        const params = createEmptyOptionDayWiseParam(
          OptionStrategyTargetProfitFieldsGroupWise,
        );
        // day_wise_tg.push(params);
        optionDayWiseWithParentKey[key].push(params);
      } else {
        const params = createEmptyOptionDayWiseParam(
          OptionStrategyTrailingStopLossFieldsGroupWise,
        );
        // day_wise_tsl.push(params);
        if (optionDayWiseWithParentKey.day_wise_sl.length === 0) {
          optionDayWiseWithParentKey[key].pop();
        } else {
          optionDayWiseWithParentKey[key].push(params);
        }
      }

      if (sb_opt_strategy[key].length === 0)
        sendValidation(`dayWise-${key}-0`, false);
      else
        sendValidation(
          `dayWise-${key}-${
            (sb_opt_strategy[key] as OptionDayWiseParam[]).length - 1
          }`,
          false,
        );

      setOptionDayWiseWithParentKey(cloneDeep(optionDayWiseWithParentKey));
      handleChangeBuilderData(cloneDeep(optionBuilderData));
    },
    [
      handleChangeBuilderData,
      optionBuilderData,
      optionDayWiseWithParentKey,
      sb_opt_strategy,
      sendValidation,
    ],
  );

  const onChangeDayWise = useCallback(
    (index: number, dayWiseParams: OptionDayWiseParam, key: DayWiseKey) => {
      if (optionDayWiseWithParentKey[key][index]) {
        optionDayWiseWithParentKey[key][index] = dayWiseParams;
      } else {
        optionDayWiseWithParentKey[key].push(dayWiseParams);
      }

      setOptionDayWiseWithParentKey(cloneDeep(optionDayWiseWithParentKey));

      if (key === DAY_WISE_STOPLOSS) day_wise_sl[index] = dayWiseParams;
      else if (key === DAY_WISE_TARGETPROFIT)
        day_wise_tg[index] = dayWiseParams;
      else day_wise_tsl[index] = dayWiseParams;

      handleChangeBuilderData(cloneDeep(optionBuilderData));
    },
    [
      day_wise_sl,
      day_wise_tg,
      day_wise_tsl,
      handleChangeBuilderData,
      optionBuilderData,
      optionDayWiseWithParentKey,
    ],
  );

  const onDeleteDayWise = useCallback(
    (index: number, key: DayWiseKey) => {
      if (
        validationDataRef.current &&
        Object.keys(validationDataRef.current).length > 0
      ) {
        delete validationDataRef.current[`dayWise-${key}-${index}`];
        Object.keys(validationDataRef.current).forEach(elem => {
          if (elem.includes(`dayWise-${key}-`)) {
            const elemIndex = parseInt(elem.split(`dayWise-${key}-`)[1]);
            if (elemIndex && !isNaN(elemIndex) && index < elemIndex) {
              validationDataRef.current[`dayWise-${key}-${elemIndex - 1}`] =
                validationDataRef.current[elem];
              delete validationDataRef.current[elem];
            }
          }
        });
      }

      if (key === DAY_WISE_STOPLOSS) {
        day_wise_sl.splice(index, 1);
        if (day_wise_sl.length < 1) {
          day_wise_tsl.length = 0;
        }
      } else if (key === DAY_WISE_TARGETPROFIT) day_wise_tg.splice(index, 1);
      else day_wise_tsl.splice(index, 1);
      handleChangeBuilderData(cloneDeep(optionBuilderData));

      optionDayWiseWithParentKey[key].splice(index, 1);
      setOptionDayWiseWithParentKey(cloneDeep(optionDayWiseWithParentKey));
    },
    [
      day_wise_sl,
      day_wise_tg,
      day_wise_tsl,
      handleChangeBuilderData,
      optionBuilderData,
      optionDayWiseWithParentKey,
    ],
  );

  const strategies = useMemo(() => {
    return [
      {
        label: 'Strategy Stop Loss',
        key: DAY_WISE_STOPLOSS,
        dayWiseData: day_wise_sl ? day_wise_sl : null,
        onChange: (index: number, dayWiseParams: OptionDayWiseParam) =>
          onChangeDayWise(index, dayWiseParams, DAY_WISE_STOPLOSS),
        onDelete: (index: number) => onDeleteDayWise(index, DAY_WISE_STOPLOSS),
      },
      {
        label: 'Strategy Target Profit',
        key: DAY_WISE_TARGETPROFIT,
        dayWiseData: day_wise_tg ? day_wise_tg : null,
        onChange: (index: number, dayWiseParams: OptionDayWiseParam) =>
          onChangeDayWise(index, dayWiseParams, DAY_WISE_TARGETPROFIT),
        onDelete: (index: number) =>
          onDeleteDayWise(index, DAY_WISE_TARGETPROFIT),
      },
      {
        label: 'Strategy TSL',
        key: DAY_WISE_TARGETSTOPLOSS,
        dayWiseData: day_wise_tsl ? day_wise_tsl : null,
        onChange: (index: number, dayWiseParams: OptionDayWiseParam) =>
          onChangeDayWise(index, dayWiseParams, DAY_WISE_TARGETSTOPLOSS),
        onDelete: (index: number) =>
          onDeleteDayWise(index, DAY_WISE_TARGETSTOPLOSS),
      },
    ];
  }, [
    day_wise_sl,
    day_wise_tg,
    day_wise_tsl,
    onChangeDayWise,
    onDeleteDayWise,
  ]);

  const isSetNameExist = useCallback(
    (name: string, index: number) => {
      const existSetData = strategy_sets.filter(
        (x, i) => x.name === name && index !== i,
      );
      return existSetData.length > 0;
    },
    [strategy_sets],
  );

  // const handleSetClick = (startIndex: number) => {
  //   setVisibleSets(
  //     [startIndex, startIndex + 1, startIndex + 2].filter(
  //       index => index < strategy_sets.length,
  //     ),
  //   );
  // };

  // const getButtonLabels = () => {
  //   const buttons: string[] = [];
  //   for (let i = 0; i < strategy_sets.length; i += 3) {
  //     const end = Math.min(i + 3, strategy_sets.length);
  //     buttons.push(`${i + 1}-${end}`);
  //   }
  //   return buttons;
  // };
  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [strategy_sets]);

  const handleToggleSet = (index: number) => {
    if (visibleSets.includes(index)) {
      // Collapse the set if it is already expanded
      setVisibleSets(visibleSets.filter(i => i !== index));
    } else {
      // Expand the set if it is not visible and ensure only 3 sets are expanded
      if (visibleSets.length === 3) {
        setVisibleSets(prevSets => [...prevSets.slice(1), index]);
      } else {
        setVisibleSets(prevSets => [...prevSets, index]);
      }
    }
  };
  return (
    <>
      {/* <h1>Option Builder</h1> */}
      {/* <Grid container columnSpacing={1}>
        {OptionStrategyFields.map((field: FieldParam) => {
          return (
            <field.renderer
              param={field as Omit<FieldParam, 'renderer' | 'type'>}
              onChange={onChangeBuilder}
              key={field.key}
              selected={optionBuilderData[field.key] as string}
              sendValidation={sendValidation}
              isReadOnly={isReadOnly}
            />
          );
        })}
      </Grid> */}
      <Box>
        {strategy_sets.map((set, index) => {
          return (
            <Box key={index}>
              {!visibleSets.includes(index) && (
                <Box
                  mt={1}
                  display="flex"
                  alignItems="center"
                  onClick={() => handleToggleSet(index)}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: visibleSets.includes(index)
                      ? 'grey.100'
                      : 'transparent',

                    border: `1px dashed ${theme.palette.action.disabledBackground}`,
                    padding: 2,
                    borderRadius: '12px',
                    position: 'relative',
                    marginTop: '16px',
                    // ...((isErrorOptioSet || isErrorExistName) && {
                    //   borderColor: 'error.main',
                    // }),

                    '&:hover': {
                      backgroundColor: 'grey.200',
                    },
                  }}
                >
                  {visibleSets.includes(index) ? (
                    <ExpandMore sx={{ marginRight: '10px' }} />
                  ) : (
                    <ChevronRight sx={{ marginRight: '10px' }} />
                  )}

                  <Box
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    flexWrap={'wrap'}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        lineHeight: '32px',
                        color: 'text.primary',
                      }}
                    >
                      {set.name}
                    </span>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '0.5em',
                        paddingRight: '3px',
                      }}
                      flexWrap={'wrap'}
                    >
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: theme.palette.custom.hoverOnLight,
                          padding: '0.2em 0.4em',
                          borderRadius: '0.25em',
                          marginRight: '2px',
                        }}
                      >
                        {'Symbol'}:
                      </Typography>
                      <Typography
                        variant="caption"
                        color="primary.main"
                        sx={{ fontWeight: '600', marginLeft: '0.2em' }}
                      >
                        {String(set.parent_symbol['symbol'])
                          .split('_')
                          .join(' ')}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ marginLeft: '0.2em' }}
                      >
                        ,{' '}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: theme.palette.custom.hoverOnLight,
                          padding: '0.2em 0.4em',
                          borderRadius: '0.25em',
                          marginRight: '2px',
                        }}
                      >
                        {'Position Type'}:
                      </Typography>
                      <Typography
                        variant="caption"
                        color="primary.main"
                        sx={{ fontWeight: '600', marginLeft: '0.2em' }}
                      >
                        {toTitleCase(
                          String(
                            set.parent_position_type['position_type'],
                          ).replace(/_/, '/'),
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ marginLeft: '0.2em' }}
                      >
                        ,{' '}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: theme.palette.custom.hoverOnLight,
                          padding: '0.2em 0.4em',
                          borderRadius: '0.25em',
                          marginRight: '2px',
                        }}
                      >
                        {'Legs'}:
                      </Typography>
                      <Typography
                        variant="caption"
                        color="primary.main"
                        sx={{ fontWeight: '600', marginLeft: '0.2em' }}
                      >
                        {set.legs.length}
                      </Typography>
                      {/* {i < Object.entries(filteredValues).length - 1 && (
              <Typography variant="subtitle2" sx={{ marginLeft: '0.2em' }}>
                ,
              </Typography>
            )} */}
                    </Box>
                  </Box>
                </Box>
              )}
              <Collapse
                in={visibleSets.includes(index)}
                timeout="auto"
                unmountOnExit
              >
                <OptionSet
                  formikState={formikCallBack}
                  initialFormikState={formikValue}
                  optionSet={set}
                  strategyIndex={index}
                  onChangeSet={onChangeSet}
                  onDeleteSet={onDeleteSet}
                  PlValidation={sendValidation}
                  isReadOnly={isReadOnly}
                  handleCopyStrategy={handleCopyStrategy}
                  handlePasteStrategy={handlePasteStrategy}
                  copiedOptionStrategy={copiedOptionStrategy}
                  isShowOptionSetValidation={isShowOptionSetValidation}
                  isSetNameExist={isSetNameExist}
                  handleToggleSet={handleToggleSet}
                />
              </Collapse>
            </Box>
          );
        })}
      </Box>
      {strategies.map((strategy, j) => (
        <Box key={j}>
          {strategy.dayWiseData && strategy.dayWiseData.length > 0 && (
            <Box
              sx={{
                border: '1px dashed',
                borderColor: theme.palette.custom.border,
                p: 3,
                pb: 0,
                borderRadius: '12px',
                position: 'relative',
                mt: 4,
                // backgroundColor: '',
              }}
            >
              <Box sx={{ lineHeight: '32px', color: 'primary.main', mb: 3 }}>
                {strategy.label}
              </Box>
              {strategy.dayWiseData.map((item, index) => (
                <Box key={`${j}_${index}`}>
                  <OptionDayWiseSSL
                    propKey={strategy.key}
                    dayWiseIndex={index}
                    isReadOnly={isReadOnly}
                    dayWiseSSL={item}
                    onChange={strategy.onChange}
                    onDelete={strategy.onDelete}
                    PlValidation={sendValidation}
                  />
                  {strategy.dayWiseData!.length - 1 !== index && (
                    <Divider sx={{ mb: 4 }} />
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ))}

      {isReadOnly ? (
        <></>
      ) : (
        <>
          <Box mt={2} display="flex" gap={2} flexWrap="wrap">
            <MuiBuilderButton
              variant="outlined"
              onClick={onClickAddOptionSet}
              startIcon={<AddIcon />}
            >
              Set
            </MuiBuilderButton>
            <FeatureFlag
              features={[FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_STOPLOSS]}
              fallback={<></>}
            >
              <MuiBuilderButton
                variant="outlined"
                onClick={() => onClickAddStrategyData(DAY_WISE_STOPLOSS)}
                startIcon={<AddIcon />}
              >
                Strategy Stop Loss
              </MuiBuilderButton>
            </FeatureFlag>
            <FeatureFlag
              features={[FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_TARGETPROFIT]}
              fallback={<></>}
            >
              <MuiBuilderButton
                variant="outlined"
                onClick={() => onClickAddStrategyData(DAY_WISE_TARGETPROFIT)}
                startIcon={<AddIcon />}
              >
                Strategy Target Profit
              </MuiBuilderButton>
            </FeatureFlag>
            <FeatureFlag
              features={[FEATURES.IS_OPTION_BOTBUILDER_STRATEGY_TARGETSTOPLOSS]}
              fallback={<></>}
            >
              <MuiBuilderButton
                // disabled={optionDayWiseWithParentKey.day_wise_sl.length == 0}
                variant="outlined"
                onClick={() => {
                  if (optionDayWiseWithParentKey.day_wise_sl.length == 0)
                    showAlert!(
                      'You Need to add Strategy Stop Loss in order to use Strategy TSL',
                    );
                  else onClickAddStrategyData(DAY_WISE_TARGETSTOPLOSS);
                }}
                startIcon={<AddIcon />}
              >
                Strategy TSL
              </MuiBuilderButton>
            </FeatureFlag>
          </Box>
        </>
      )}
    </>
  );
};

export default memo(OptionsBuilder);
