import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { useHistory } from 'react-router-dom';
import { isUserLoggedIn } from 'utils/auth';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  Assessment,
  AssessmentOutlined,
  DomainAdd,
  DomainAddOutlined,
  Link,
  LinkOutlined,
  Settings,
  SettingsOutlined,
  TipsAndUpdates,
  TipsAndUpdatesOutlined,
} from '@mui/icons-material';
import { Badge, Box } from '@mui/material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LockIcon from '@mui/icons-material/Lock';
import { memo, useContext } from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { themeContext } from 'app/components/Context/ToggleThemeContext';
import { useTheme } from '@mui/material/styles';
const SideBarNavigationMenu = ({
  open,
  activeNav,
  loginModal,
}: {
  open: boolean;
  activeNav: string;
  loginModal: (isLogin: boolean) => void;
}) => {
  const history = useHistory();
  const isLoggedIn = isUserLoggedIn();
  const isTraders = useIsFeatureFlag([FEATURES.IS_TRADERS]);
  const isDiscover = useIsFeatureFlag([FEATURES.IS_DISCOVER]);
  const isOptionChain = useIsFeatureFlag([FEATURES.IS_OPTIONCHAIN]);
  const isTemplates = useIsFeatureFlag([FEATURES.IS_TEMPLATES]);
  const isBots = useIsFeatureFlag([FEATURES.IS_BOTS]);
  const isMyPortfolio = useIsFeatureFlag([FEATURES.IS_MY_PORTFOLIO]);
  const isReport = useIsFeatureFlag([FEATURES.IS_REPORT]);
  const isHomePage = useIsFeatureFlag([FEATURES.IS_HOME_PAGE]);
  const isTradeIdeas = useIsFeatureFlag([FEATURES.IS_TRADE_IDEAS]);
  const isMybotsInPortfolio = useIsFeatureFlag([
    FEATURES.IS_MYBOTS_IN_PORTFOLIO,
  ]);
  const isDarkThemeFeature = useIsFeatureFlag([FEATURES.IS_DARK_THEME_FEATURE]);
  // const [darkMode, setDarkMode] = useState(false);

  // const handleThemeChange = () => {
  //   setDarkMode(!darkMode);
  // };
  const { isDarkTheme, toggleTheme } = useContext(themeContext);
  const handleNavMenuClick = (value: string) => {
    if (value === 'login') loginModal(true);
    else history.push(`/${value}`);
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <List>
        {isHomePage && (
          <MenuListButton
            open={open}
            label="Home"
            icon={activeNav === 'home' ? <HomeIcon /> : <HomeOutlinedIcon />}
            activeMenu={activeNav === 'home'}
            setValue={handleNavMenuClick}
            value="home"
          />
        )}
        {isLoggedIn && (
          <MenuListButton
            label={`${isMyPortfolio ? 'My ' : ''}Bots`}
            icon={
              activeNav === 'portfolio' ? (
                <LeaderboardIcon />
              ) : (
                <LeaderboardOutlinedIcon />
              )
            }
            value="portfolio"
            activeMenu={activeNav === 'portfolio'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && (
          <MenuListButton
            label={`${isMyPortfolio ? 'My ' : ''}Bots`}
            icon={
              activeNav === 'portfolio' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <LeaderboardIcon />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <LeaderboardIcon />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'portfolio'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && isDiscover && (
          <MenuListButton
            label="Discover"
            icon={
              activeNav === 'discover' ? <StoreIcon /> : <StoreOutlinedIcon />
            }
            value="discover/bots"
            activeMenu={activeNav === 'discover'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && isDiscover && (
          <MenuListButton
            label="Discover"
            icon={
              activeNav === 'discover' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <StoreIcon />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <StoreOutlinedIcon />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'discover'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && isTraders && (
          <MenuListButton
            label="Traders"
            icon={
              activeNav === 'traders' ? <PeopleIcon /> : <PeopleOutlinedIcon />
            }
            value="traders"
            activeMenu={activeNav === 'traders'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && isTraders && (
          <MenuListButton
            label="Traders"
            icon={
              activeNav === 'traders' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <PeopleIcon />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <PeopleOutlinedIcon />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'traders'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && isBots && !isMybotsInPortfolio && (
          <MenuListButton
            label="My Bots"
            icon={
              activeNav === 'bots' ? <WidgetsIcon /> : <WidgetsOutlinedIcon />
            }
            value="bots"
            activeMenu={activeNav === 'bots'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && isBots && !isMybotsInPortfolio && (
          <MenuListButton
            label="My Bots"
            icon={
              activeNav === 'bots' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <WidgetsIcon />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <WidgetsOutlinedIcon />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'bots'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && isOptionChain && (
          <MenuListButton
            label="Option chain"
            icon={activeNav === 'option-chain' ? <Link /> : <LinkOutlined />}
            value="option-chain"
            activeMenu={activeNav === 'option-chain'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && isOptionChain && (
          <MenuListButton
            label="Option chain"
            icon={
              activeNav === 'option-chain' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <Link />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <LinkOutlined />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'option-chain'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && isTradeIdeas && (
          <MenuListButton
            label="Trade Ideas"
            icon={
              activeNav === 'trade-options' ? (
                <TipsAndUpdates />
              ) : (
                <TipsAndUpdatesOutlined />
              )
            }
            value="trade-options"
            activeMenu={activeNav === 'trade-options'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && isTradeIdeas && (
          <MenuListButton
            label="Trade Ideas"
            icon={
              activeNav === 'trade-options' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <TipsAndUpdates />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <TipsAndUpdatesOutlined />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'trade-options'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && isReport && (
          <MenuListButton
            label="Report"
            icon={
              activeNav === 'report' ? (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <Assessment />
                </Badge>
              ) : (
                <Badge badgeContent={<LockIcon style={{ fontSize: '17' }} />}>
                  <AssessmentOutlined />
                </Badge>
              )
            }
            value="login"
            activeMenu={activeNav === 'report'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isTemplates && (
          <MenuListButton
            label="Templates"
            icon={
              activeNav === 'templates' ? (
                <WidgetsIcon />
              ) : (
                <WidgetsOutlinedIcon />
              )
            }
            value="templates"
            activeMenu={activeNav === 'templates'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && (
          <MenuListButton
            label="Brokers"
            icon={
              activeNav === 'brokers' ? <DomainAdd /> : <DomainAddOutlined />
            }
            value="brokers"
            activeMenu={activeNav === 'brokers'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isLoggedIn && isReport && (
          <MenuListButton
            label="Report"
            icon={
              activeNav === 'report' ? <Assessment /> : <AssessmentOutlined />
            }
            value="report"
            activeMenu={activeNav === 'report'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}

        {isLoggedIn && (
          <MenuListButton
            label="Settings"
            icon={activeNav === 'menu' ? <Settings /> : <SettingsOutlined />}
            value="menu"
            activeMenu={activeNav === 'menu'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {!isLoggedIn && (
          <MenuListButton
            label="Sign In"
            icon={<LoginIcon />}
            value="login"
            activeMenu={activeNav === 'menu'}
            setValue={handleNavMenuClick}
            open={open}
          />
        )}
        {isDarkThemeFeature && (
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={toggleTheme}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
                color="inherit"
              >
                {/* <IconButton> */}
                {isDarkTheme ? <Brightness4Icon /> : <Brightness7Icon />}
                {/* </IconButton> */}
              </ListItemIcon>

              <ListItemText
                primary={'Theme'}
                sx={{
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default memo(SideBarNavigationMenu);

const MenuListButton = ({
  label,
  open,
  icon,
  activeMenu,
  setValue,
  value,
}: {
  label: string;
  open: boolean;
  icon: ReactJSXElement;
  activeMenu: boolean;
  setValue: (value: string) => void;
  value: string;
}) => {
  const theme = useTheme();
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() => setValue(value)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
            color: activeMenu ? theme.palette.common.black : '',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{
            opacity: open ? 1 : 0,
            color: activeMenu
              ? theme.palette.common.black
              : theme.palette.grey[600],
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};
