import { useTheme } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { PRODUCT_TOUR } from 'constants/common';
import { useHistory } from 'react-router-dom';

type ProductTourProps = {
  open: boolean;
  handleClose: () => void;
  selectedIndexs: Array<number>;
  handleSelectedIndex: (selectedIndex: number[]) => void;
};

export const productKeyFeaturesPage = [
  { title: 'Home Page', description: 'This is Home Page Featurs Tour' },
  { title: 'Bot Page', description: 'This is Bot Page Featuers Tour' },
  {
    title: 'Template Page',
    description: 'This is template Page Featuers Tour',
  },
  { title: 'Report Page', description: 'This is Report Page Features tour' },
  {
    title: 'Add Broker Page',
    description: 'This is Broker Page Features Tour',
  },
];

const ProductTourModal = ({
  open,
  handleClose,
  selectedIndexs,
  handleSelectedIndex,
}: ProductTourProps) => {
  const history = useHistory();
  const localData = JSON.parse(
    localStorage.getItem(PRODUCT_TOUR) || '',
  ) as Record<string, string>;
  const handleClick = (selectedTour: number) => {
    localStorage.setItem(
      PRODUCT_TOUR,
      JSON.stringify({
        ...localData,
        [`${productKeyFeaturesPage[selectedTour].title}`]: false,
      }),
    );
    handleSelectedIndex([...selectedIndexs, selectedTour]);
    switch (productKeyFeaturesPage[selectedTour].title) {
      case 'Home Page':
        history.push('/home');
        break;
      case 'Bot Page':
        history.push('/portfolio?ptype=active');
        break;
      case 'Template Page':
        history.push('/templates');
        break;
      case 'Report Page':
        history.push('/report');
        break;
      case 'Add Broker Page':
        history.push('/brokers');
        break;
    }
    handleClose();
  };
  return (
    <MuiFullScreenModal
      open={open}
      width="270px"
      handleClose={handleClose}
      body={
        <Box>
          {productKeyFeaturesPage.map(
            (keyFeature: { title: string; description: string }, index) => {
              return ProductTourListComponent({
                keyFeature: keyFeature,
                index: index,
                selectedIndex: selectedIndexs,
                handleClick: handleClick,
              });
            },
          )}
        </Box>
      }
    />
  );
};

const ProductTourListComponent = ({
  keyFeature,
  index,
  selectedIndex,
  handleClick,
}: {
  keyFeature: { title: string; description: string };
  index: number;
  selectedIndex: Array<number>;
  handleClick: (selectedIndex: number) => void;
}) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ':hover': {
            cursor: 'pointer',
          },
          m: 1,
        }}
        onClick={() => handleClick(index)}
        className="p-main-spacing"
      >
        <Box
          sx={{
            bgcolor: selectedIndex.includes(index)
              ? theme.palette.info.dark
              : theme.palette.grey[600],
            borderRadius: '50%',
            width: '35px',
            alignContent: 'center',
            textAlign: 'center',
            height: '35px',
            m: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: theme.palette.common.white, textAlign: 'center' }}
          >
            {index + 1}
          </Typography>
        </Box>
        <Box
          sx={{
            textDecoration: selectedIndex.includes(index) ? 'line-through' : '',
          }}
        >
          <Typography variant="body1">{keyFeature.title}</Typography>
          <Typography variant="body3">{keyFeature.description}</Typography>
        </Box>
      </Box>
      <Divider sx={{ flex: 1, borderColor: 'grey.300' }} />
    </>
  );
};

export default ProductTourModal;
